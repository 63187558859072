import styled from '@emotion/styled'
import slugify from '@sindresorhus/slugify'
import { graphql, useStaticQuery } from 'gatsby'
import { useIntl } from 'gatsby-plugin-react-intl'
import { FC } from 'react'

import Container from '../../../components/Container'
import { SubTitleStyled, TitleStyled } from '../../../components/common/Title'
import { useIntlLabel } from '../../../intl'
import ItemPopularService from './ItemPopularService'
import { HomeServicePopularSectionQuery } from './__generated__/HomeServicePopularSectionQuery'

const ContainerStyled = styled.section((props) => ({
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    padding: '40px 0',
    boxSizing: 'border-box',
}))

const ContainerListStyled = styled.div((props) => ({
    display: 'flex',
    flexWrap: 'wrap',
    margin: `0 -${props.theme.spacing(1)}`,
    paddingBottom: props.theme.spacing(5),
    //borderBottom: 'solid 1px #cfd8dc',
}))

const HomeServicePopularSection: FC = () => {
    const intl = useIntl()

    const getIntlLabel = useIntlLabel()

    const { allStrapiPopularService, strapiHome } = useStaticQuery<HomeServicePopularSectionQuery>(
        graphql`
            query HomeServicePopularSectionQuery {
                allStrapiPopularService(limit: 5, sort: { order: ASC, fields: created_at }) {
                    nodes {
                        serviceDescription
                        serviceName
                        serviceName_mg
                        serviceSlug
                        serviceSlug_mg
                        serviceCategories {
                            categoryName
                            categoryName_mg
                        }
                    }
                }
                strapiHome {
                    titlePopularServices
                    titlePopularServices_mg
                    titleVeryRequestedServices
                    titleVeryRequestedServices_mg
                }
            }
        `,
    )

    return (
        <ContainerStyled>
            <Container>
                <TitleStyled>{getIntlLabel(strapiHome, 'titleVeryRequestedServices')}</TitleStyled>
                <SubTitleStyled>
                    {intl.formatMessage({ id: 'home_most_searched_subtitle' })}
                </SubTitleStyled>
                <ContainerListStyled>
                    {allStrapiPopularService.nodes.map((item: any, index) => (
                        <ItemPopularService
                            key={`${item}-${index}`}
                            to={`/services/${item.serviceSlug}?category=${slugify(
                                item.serviceCategories[0].categoryName,
                            )}`}
                            title={getIntlLabel(item, 'serviceName')}
                            category={getIntlLabel(item.serviceCategories[0], 'categoryName')}
                        />
                    ))}
                </ContainerListStyled>
            </Container>
        </ContainerStyled>
    )

    return null
}

export default HomeServicePopularSection
